import { useMediaQuery } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React, { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { matchPath, useLocation } from 'react-router-dom';

import './App.scss';
import { LoginRoutes, RoutesConfig } from './components/Navigation/RoutesConfig';
import ResponsiveDrawer from './components/drawer/drawer';
import HeaderBar from './components/header-bar';
import LeftNav from './components/leftnav';
import Loading from './components/loading';
import aws_config from './config/aws_exports';
import insightsConfig from './config/config';
import { AuthContext, useAuth } from './hooks/useAuth';
import { FilterContext } from './hooks/useFilters';
import { checkIfAdmin, checkIfCompanyViewer, getKeyForStorage } from './utils';

Amplify.configure(aws_config);

function App() {
  const { authDetails, refreshTokenIfNeeded, updateUiConfigFnc, refreshSessionIfNeeded } =
    useAuth();
  const location = useLocation();
  const TRACKING_ID = insightsConfig.GoogleAnalytics.GA_ID;
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      cookieDomain: insightsConfig.amplify.cookieDomain,
      cookieFlags: 'samesite=None;Secure'
    }
  });
  const {
    userSession,
    isLoading,
    uiConfigs: { showLeftNav }
  } = authDetails;
  const storageKey = getKeyForStorage(authDetails);
  const memoUpdateUiConfigFnc = useCallback(updateUiConfigFnc, []);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.href
    });
    refreshTokenIfNeeded();
    refreshSessionIfNeeded();
  }, [location]);

  if (isLoading) {
    return <Loading />;
  }
  if ((userSession !== null && !isLoading) || matchPath(pathname, '/signOut')) {
    const originalUrl = sessionStorage.getItem('originalUrl');
    if (originalUrl) {
      sessionStorage.removeItem('originalUrl');
      window.location.assign(originalUrl);
      return null;
    }

    const restrictedAccessUser = !authDetails.roleDetails?.session_id;
    const isAdmin = checkIfAdmin(authDetails.roleDetails?.role);
    const isCompanyViewer = checkIfCompanyViewer(authDetails.roleDetails?.role);
    return (
      <AuthContext.Provider
        value={{
          authDetails,
          updateUiConfigFnc: memoUpdateUiConfigFnc,
          refreshTokenIfNeeded,
          refreshSessionIfNeeded
        }}
      >
        <FilterContext.Provider value={{ storageKey }}>
          <div className="App">
            <header className="App-header">
              <HeaderBar
                userDetails={userSession?.idToken?.payload}
                restrictedAccessUser={restrictedAccessUser}
                handleDrawerToggle={handleDrawerToggle}
                isAdmin={isAdmin}
              />
            </header>
            {isMobile && !restrictedAccessUser && (
              <ResponsiveDrawer
                drawerOpen={drawerOpen}
                handleDrawerToggle={handleDrawerToggle}
                isAdmin={isCompanyViewer}
              />
            )}
            {showLeftNav && !isMobile && !restrictedAccessUser && (
              <div className="App-leftnav">
                <LeftNav isAdmin={isCompanyViewer} />
              </div>
            )}
            <section className="App-body">
              <div className={showLeftNav ? 'App-content' : 'App-content App-content-full'}>
                <RoutesConfig />
              </div>
            </section>
            <footer className="App-footer">
              <div className="footer-div">
                <p>Greyscale AI &#xA9; {new Date().getFullYear()}</p>
                <p>Terms and Conditions</p>
              </div>
            </footer>
          </div>
        </FilterContext.Provider>
      </AuthContext.Provider>
    );
  } else {
    return <LoginRoutes />;
  }
}

export default App;
